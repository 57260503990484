import { useRouteMatch } from 'react-router-dom'
import { baseUrl } from 'store/actions/utils'

import commons from '../i18n/commons'
import {
  countryIdToCode,
  invertDictionary,
  locationTypesToRequestType,
  propertyTypes,
  publicationTypes,
} from './dictionaries'
import { parameterizePropertyId } from './propertyProfiles'

// Turns a route into a readable string.
export const humanize = route =>
  route
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s-]+/g, ' ')
    .replace(/^[a-z]| [a-z]/g, m => m.toUpperCase())

// Decomposes a path into an array of objects containing
// each humanized route with its absolute path.
export const routeReducer = (arr, label, i) => [
  ...arr,
  {
    label: humanize(label),
    path: arr[i - 1] ? `${arr[i - 1].path}/${label}` : `/${label}`,
  },
]

export const addRegexpPrefix = path => {
  const absolute = path[0] === '/'
  return `${absolute ? '/:urlCountry' : ':urlCountry'}(\\w{2})/${path.slice(absolute ? 1 : 0)}`
}

export const addPrefixArray = paths => paths.map(p => addRegexpPrefix(p))

export const prefixRoute = (to, prefix) => `/${prefix}${to}`

export const useCountryPath = to => {
  const match = useRouteMatch()
  const country = match.params.urlCountry.slice(0, 2)
  return `/${country}${to}`
}

export const urlCleanString = str => {
  if (!str) return ''
  let from = str.toLowerCase()
  const toReplace = 'ÁÃÀÄÂÉËÈÊÍÏÌÎÓÖÒÔÚÜÙÛÑÇáãàäâéëèêíïìîóöòôúüùûñç'
  const replaceWith = 'AAAAAEEEEIIIIOOOOUUUUNCaaaaaeeeeiiiioooouuuunc',
    re = new RegExp('[' + toReplace + ']', 'ug')
  const to = from.replace(re, match => replaceWith.charAt(toReplace.indexOf(match)))
  return to
}

export const createUrl = (
  section,
  { modeID, typeID, commune, title, propertyID, countryID, countryCode, communeID, locationType }
) => {
  const country = countryCode || countryIdToCode[countryID]

  switch (section) {
    case 'propertyProfile':
      return `/${country}/${commons['propiedad'][country]}/${commons[publicationTypes[modeID]][country]}/${
        commons[propertyTypes[typeID]][country]
      }/${commune ? urlCleanString(commune).toLowerCase().replace(/ /g, '-') : country}/${parameterizePropertyId(
        propertyID,
        title
      )}`
    case 'projectProfile':
      return `/${country}/${commons['proyecto'][country]}/${
        commune ? urlCleanString(commune).toLowerCase().replace(/ /g, '-') : country
      }/${parameterizePropertyId(propertyID, title)}`
    case 'enterprise':
      return `/${country}/`
    case 'locationLanding':
      const communePath = `/${country}/${commons[propertyTypes[typeID]][country]}/${
        commons[publicationTypes[modeID]][country]
      }/${invertDictionary(locationTypesToRequestType[country])[locationType]}/${communeID}-${urlCleanString(commune)
        .toLowerCase()
        .replace(/ /g, '-')}`

      return communePath
    case 'countryLanding':
      const countryPath = `/${country}/${commons[propertyTypes[typeID]][country]}/${
        commons[publicationTypes[modeID]][country]
      }`
      return countryPath
    default:
      return null
  }
}

export const communeWithLocationTypePattern =
  /^\/(?:[a-z]{2}\/)?(casa|departamento|apartamento|oficina|propiedad|inmueble|terreno|estacionamiento|bodega)\/(venta|arriendo|renta|alquiler)\/(depto|region|provincia|comuna|barrio|colonia|propiedad|estado|municipio|inmueble|localidad|distrito|urbanizacion|zona|parroquia|ciudad)\/(\d{1,6})(?:-([a-zA-Z-]+))?$/

export const communeWithoutLocationTypePattern =
  /^\/(?:[a-z]{2}\/)?(casa|departamento|apartamento|oficina|propiedad|inmueble|terreno|estacionamiento|bodega)\/(venta|arriendo|renta|alquiler)$/

export const propertyPattern =
  /^\/(?:[a-z]{2}\/)?(?:inmueble|propiedad)\/?(venta|arriendo|renta|alquiler)?\/?(casa|departamento|apartamento|oficina|propiedad|inmueble|terreno|estacionamiento|bodega)?\/?(.*?[\d-]+\w*)$/

export const projectPattern = /^\/(?:[a-z]{2}\/)?(proyecto|emprendimiento|desarrollo)\/(\d+[-\w]*)$/

export const enterprisePattern = /^\/(?:[a-z]{2}\/)?empresa\/(\d+[-\w]*)?$/

export const enterpriseAssociatesPattern = /^\/(?:[a-z]{2}\/)?asociados\/empresa\/.*/

export const superEnterpriseAssociatesPattern = /^\/(?:[a-z]{2}\/)?asociados\/superempresa\/.*/

const getType = url =>
  url.match(/(casa|departamento|apartamento|oficina|propiedad|inmueble|terreno|estacionamiento|bodega)/)[0]
const getMode = url => url.match(/(venta|arriendo|renta|alquiler)/)[0]
const getLocationType = url =>
  url.match(
    /(depto|region|provincia|comuna|barrio|colonia|propiedad|estado|municipio|inmueble|localidad|distrito|urbanizacion|zona|parroquia|ciudad)/
  )[0]
const getId = url => {
  const match = url.match(/\/(\d{1,10})(?:-[^/]+)?$/)
  if (match) {
    return match[1]
  }
  return null
}
const getCountry = url => invertDictionary(countryIdToCode)[url.match(/(ar|cl|pe|uy|co|mx)/)[0]]
const getAsocciateID = url => url.match(/\/([^/]+)$/)?.[1] || null

export const cleanURL = window.location.href.split('?')[0]

export const buildRedirectURLBasedOnEnvironment = url => {
  if (process.env.NODE_ENV === 'production') return url
  return url.replace('https://www.stg.goplaceit.com', 'http://localhost:3000')
}

export const getCommuneWithLocationTypeRequestURL = url => {
  const type = getType(url)
  const mode = getMode(url)
  const locationType = getLocationType(url)
  const id = getId(url)
  const country = getCountry(url)
  return `${baseUrl}routes/commune?type=${type}&mode=${mode}&country=${country}&location_type=${locationType}&id=${id}`
}

export const getCommuneWithoutLocationTypeRequestURL = url => {
  const type = getType(url)
  const mode = getMode(url)
  const country = getCountry(url)
  return `${baseUrl}routes/commune?type=${type}&mode=${mode}&country=${country}`
}

export const getPropertyRequestURL = url => {
  const id = getId(url)
  return `${baseUrl}routes/properties/${id}`
}

export const getProjectRequestURL = url => {
  const id = getId(url)
  return `${baseUrl}routes/projects/${id}`
}

export const getEnterpriseRequestURL = url => {
  const id = getId(url)
  return `${baseUrl}routes/enterprises/${id}`
}

export const getEnterpriseAssociatesRequestURL = url => {
  const id = getAsocciateID(url)
  const enterpriseId = id.split('-')[0]
  const propertyId = id.substring(id.indexOf('-') + 1)
  return `${baseUrl}routes/associates/enterprises?enterprise_id=${enterpriseId}&property_id=${propertyId}`
}

export const getSuperEnterpriseAssociatesRequestURL = url => {
  const id = getAsocciateID(url)
  const enterpriseId = id.split('-')[0]
  const propertyId = id.substring(id.indexOf('-') + 1)
  return `${baseUrl}routes/associates/super_enterprises?enterprise_id=${enterpriseId}&property_id=${propertyId}`
}

export const wellknownLocations = {
  cl: [
    '1-iquique',
    '2-camina',
    '3-colchane',
    '4-huara',
    '5-pica',
    '6-pozo-almonte',
    '7-antofagasta',
    '8-mejillones',
    '9-sierra-gorda',
    '10-taltal',
    '11-calama',
    '12-ollague',
    '13-san-pedro-de-atacama',
    '14-tocopilla',
    '15-maria-elena',
    '16-copiapo',
    '17-caldera',
    '18-tierra-amarilla',
    '19-chanaral',
    '20-diego-de-almagro',
    '21-vallenar',
    '22-alto-del-carmen',
    '23-freirina',
    '24-huasco',
    '25-la-serena',
    '26-coquimbo',
    '27-andacollo',
    '28-la-higuera',
    '29-paiguano',
    '30-vicuna',
    '31-illapel',
    '32-canela',
    '33-los-vilos',
    '34-salamanca',
    '35-ovalle',
    '36-combarbala',
    '37-monte-patria',
    '38-punitaqui',
    '39-rio-hurtado',
    '40-valparaiso',
    '41-casablanca',
    '42-concon',
    '43-juan-fernandez',
    '44-puchuncavi',
    '45-quilpue',
    '46-quintero',
    '47-villa-alemana',
    '48-vina-del-mar',
    '49-isla-de-pascua',
    '50-los-andes',
    '51-calle-larga',
    '52-rinconada',
    '53-san-esteban',
    '54-la-ligua',
    '55-cabildo',
    '56-papudo',
    '57-petorca',
    '58-zapallar',
    '59-quillota',
    '60-calera',
    '61-hijuelas',
    '62-la-cruz',
    '63-limache',
    '64-nogales',
    '65-olmue',
    '66-san-antonio',
    '67-algarrobo',
    '68-cartagena',
    '69-el-quisco',
    '70-el-tabo',
    '71-santo-domingo',
    '72-san-felipe',
    '73-catemu',
    '74-llaillay',
    '75-panquehue',
    '76-putaendo',
    '77-santa-maria',
    '78-rancagua',
    '79-codegua',
    '80-coinco',
    '81-coltauco',
    '82-donihue',
    '83-graneros',
    '84-las-cabras',
    '85-machali',
    '86-malloa',
    '87-mostazal',
    '88-olivar',
    '89-peumo',
    '90-pichidegua',
    '91-quinta-de-tilcoco',
    '92-rengo',
    '93-requinoa',
    '94-san-vicente',
    '95-pichilemu',
    '96-la-estrella',
    '97-litueche',
    '98-marchihue',
    '99-navidad',
    '100-paredones',
    '101-san-fernando',
    '102-chepica',
    '103-chimbarongo',
    '104-lolol',
    '105-nancagua',
    '106-palmilla',
    '107-peralillo',
    '108-placilla',
    '109-pumanque',
    '110-santa-cruz',
    '111-talca',
    '112-constitucion',
    '113-curepto',
    '114-empedrado',
    '115-maule',
    '116-pelarco',
    '117-pencahue',
    '118-rio-claro',
    '119-san-clemente',
    '120-san-rafael',
    '121-cauquenes',
    '122-chanco',
    '123-pelluhue',
    '124-curico',
    '125-hualane',
    '126-licanten',
    '127-molina',
    '128-rauco',
    '129-romeral',
    '130-sagrada-familia',
    '131-teno',
    '132-vichuquen',
    '133-linares',
    '134-colbun',
    '135-longavi',
    '136-parral',
    '137-retiro',
    '138-san-javier',
    '139-villa-alegre',
    '140-yerbas-buenas',
    '141-concepcion',
    '142-coronel',
    '143-chiguayante',
    '144-florida',
    '145-hualqui',
    '146-lota',
    '147-penco',
    '148-san-pedro-de-la-paz',
    '149-santa-juana',
    '150-talcahuano',
    '151-tome',
    '152-lebu',
    '153-arauco',
    '154-canete',
    '155-contulmo',
    '156-curanilahue',
    '157-los-alamos',
    '158-tirua',
    '160-antuco',
    '161-cabrero',
    '162-laja',
    '163-mulchen',
    '164-nacimiento',
    '165-negrete',
    '166-quilaco',
    '167-quilleco',
    '168-san-rosendo',
    '169-santa-barbara',
    '170-tucapel',
    '171-yumbel',
    '172-chillan',
    '173-bulnes',
    '174-cobquecura',
    '175-coelemu',
    '176-coihueco',
    '177-chillan-viejo',
    '178-el-carmen',
    '179-ninhue',
    '180-niquen',
    '181-pemuco',
    '182-pinto',
    '183-portezuelo',
    '184-quillon',
    '185-quirihue',
    '186-ranquil',
    '187-san-carlos',
    '188-san-fabian',
    '189-san-ignacio',
    '190-san-nicolas',
    '191-treguaco',
    '192-yungay',
    '193-temuco',
    '194-carahue',
    '195-cunco',
    '196-curarrehue',
    '197-freire',
    '198-galvarino',
    '199-gorbea',
    '200-lautaro',
    '201-loncoche',
    '202-melipeuco',
    '203-nueva-imperial',
    '204-padre-las-casas',
    '205-perquenco',
    '206-pitrufquen',
    '207-pucon',
    '208-saavedra',
    '209-teodoro-schmidt',
    '210-tolten',
    '211-vilcun',
    '212-villarrica',
    '213-angol',
    '214-collipulli',
    '215-curacautin',
    '216-ercilla',
    '217-lonquimay',
    '218-los-sauces',
    '219-lumaco',
    '220-puren',
    '221-renaico',
    '222-traiguen',
    '223-victoria',
    '224-puerto-montt',
    '225-calbuco',
    '226-cochamo',
    '227-fresia',
    '228-frutillar',
    '229-los-muermos',
    '230-llanquihue',
    '231-maullin',
    '232-puerto-varas',
    '233-castro',
    '234-ancud',
    '235-chonchi',
    '236-curaco-de-velez',
    '237-dalcahue',
    '238-puqueldon',
    '239-queilen',
    '240-quellon',
    '241-quemchi',
    '242-quinchao',
    '243-osorno',
    '244-puerto-octay',
    '245-purranque',
    '246-puyehue',
    '247-rio-negro',
    '248-san-juan-de-la-costa',
    '249-san-pablo',
    '250-chaiten',
    '251-futaleufu',
    '252-hualaihue',
    '253-palena',
    '254-coyhaique',
    '255-lago-verde',
    '256-aysen',
    '257-cisne',
    '258-guaitecas',
    '259-cochrane',
    '260-o-higgins',
    '261-tortel',
    '262-chile-chico',
    '263-rio-ibanez',
    '264-punta-arenas',
    '265-laguna-blanca',
    '266-rio-verde',
    '267-san-gregorio',
    '268-cabo-de-horno',
    '269-antartica',
    '270-porvenir',
    '271-primavera',
    '272-timaukel',
    '273-natales',
    '274-torres-del-paine',
    '275-santiago',
    '276-cerrillos',
    '277-cerro-navia',
    '278-conchali',
    '279-el-bosque',
    '280-estacion-central',
    '281-huechuraba',
    '282-independencia',
    '283-la-cisterna',
    '284-la-florida',
    '285-la-granja',
    '286-la-pintana',
    '287-la-reina',
    '288-las-condes',
    '289-lo-barnechea',
    '290-lo-espejo',
    '291-lo-prado',
    '292-macul',
    '293-maipu',
    '294-nunoa',
    '295-pedro-aguirre-cerda',
    '296-penalolen',
    '297-providencia',
    '298-pudahuel',
    '299-quilicura',
    '300-quinta-normal',
    '301-recoleta',
    '302-renca',
    '303-san-joaquin',
    '304-san-miguel',
    '305-san-ramon',
    '306-vitacura',
    '307-puente-alto',
    '308-pirque',
    '309-san-jose-de-maipo',
    '310-colina',
    '311-lampa',
    '312-tiltil',
    '313-san-bernardo',
    '314-buin',
    '315-calera-de-tango',
    '316-paine',
    '317-melipilla',
    '318-alhue',
    '319-curacavi',
    '320-maria-pinto',
    '321-san-pedro',
    '322-talagante',
    '323-el-monte',
    '324-isla-de-maipo',
    '325-padre-hurtado',
    '326-penaflor',
    '327-valdivia',
    '328-corral',
    '332-lanco',
    '333-los-lagos',
    '334-mafil',
    '335-mariquina',
    '336-paillaco',
    '337-panguipulli',
    '339-la-union',
    '340-futrono',
    '341-lago-ranco',
    '342-rio-bueno',
    '343-arica',
    '344-camarones',
    '345-putre',
    '346-general-lagos',
    '347-hualpen',
    '374002-alto-hospicio',
    '374003-cholchol',
    '721909-alto-biobio',
    '721910-los-angeles',
    '721911-paihuano',
    '721914-marchigue',
    '721916-san-javier-de-loncomilla',
    '721923-limache',
    '721924-olmue',
    '721925-quilpue',
    '721926-villa-alemana',
    '721927-la-calera',
    '722352-isla-de-pascua',
  ],
}

export const deletedCountries = ['ar', 'co', 'pe', 'uy', 'mx']
